<template>
    <div class="main_wrapper">
        <div class="modal_wrapper"  v-if="$parent.modal">
            <div class="modal_window">
                <section class="content_pannel edit_pannel">
                    <header class="pannel_header">
                        <h3>Categorias<span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></span>Editar</h3>
                        <div class="close_pannel" @click="close()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                        </div>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Nombre</label>
                                <div class="input_block">
                                    <input type="text" v-model="categoria.name">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Peliculas ({{categoria.films.length}})</label>
                                <ul>
                                    <li v-for="(pelicula, index) in categoria.films" :key="index"><router-link :to="`/peliculas/${pelicula.id}`" target="_blank">{{pelicula.title}}</router-link></li>
                                </ul>
                            </div>
                            <div class="grid_col grid_col_1_1 separator bordered">
                                <span></span>
                            </div>
                            <div class="grid_col grid_col_1_2">
                                <div class="grid grid_pad">
                                    <div class="grid_col grid_col_1_2 form_block" @click="close()">
                                        <button class="btn med grey ko graphic graphic_right center wide">Cancelar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
                                    </div>
                                    <div class="grid_col grid_col_1_2 form_block" @click="save()">
                                        <button class="btn med grey graphic graphic_right center wide">Guardar cambios<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 3h2.996v5h-2.996v-5zm11 1v20h-24v-24h20l4 4zm-17 5h10v-7h-10v7zm15-4.171l-2.828-2.829h-.172v9h-14v-9h-3v20h20v-17.171z"/></svg></button>
                                    </div>
                                    <div v-if="this.categoria.id" class="grid_col grid_col_1_2 form_block" @click="eliminar()">
                                        <button class="btn med grey ko graphic graphic_right center wide">Eliminar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <main class="content_wrapper">
            <div class="inner">
                <section class="page_header">
                    <h2>Categorias</h2>
                    <div class="page_header_nav">
                        <div class="btn med grey graphic_right" @click="nuevo()">
                            <span>Agregar nuevo</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                        </div>
                    </div>
                </section>
                <section class="content_pannel">
                    <header class="pannel_header">
                        <h3>Listado de categorias</h3>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_1 form_block">
                                <VityTable 
                                    ref="vtable"
                                    v-if="url"
                                    :columns="columns"
                                    :url="url+'categories'"
                                    :params="{admin:$store.state.user}"
                                    tableFooter
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import VityTable from '@/components/VityUtils/vitytables/VityTables.vue';

export default {
    name: 'Categorias',
    components:{
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'Nombre',
                    dbName: 'name',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    center: true,
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/categorias/TPLData"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.127 22.564l-7.126 1.436 1.438-7.125 5.688 5.689zm-4.274-7.104l5.688 5.689 15.46-15.46-5.689-5.689-15.459 15.46z"/></svg></a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            categoria: null
        }
    },
    methods: {
        init: function(){
            if(this.$route.params.id)
                this.load(this.$route.params.id);
            else
                this.categoria = {}
        },
        load: function(id){
            axios
                .get(process.env.VUE_APP_URL+`categories/${id}`, {
                    params: {
                        admin: this.$store.state.user,
                        films: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.categoria = rs.data;
                        this.$parent.modal = true;
                    }
                })
        },
        close: function(){
            this.$parent.modal = false;
            this.categoria = null;
            this.$router.push('/categorias');
        },
        save: function(){
            if(!this.categoria.name){
                this.$parent.error = {type: 3, msg: 'Faltan campos por rellenar'};
                return;
            }

            axios
                .post(process.env.VUE_APP_URL+`categories`+(this.categoria.id ? `/${this.categoria.id}` : ''), {
                    admin: this.$store.state.user,
                    name: this.categoria.name
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(this.categoria.id){
                            this.$parent.error = {type: 1, msg: 'Guardado correctamente'};
                            this.init();
                        }
                        else
                            this.$router.push(`/categorias/${rs.data}`);

                        this.$refs.vtable.getDatos();
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => {
                    this.$parent.error = {type: 2, msg: 'Fallo al conectar'};
                })
        },
        eliminar: function(){
            if(!this.categoria.id)
                return;
            if(confirm("Seguro que quieres eliminarlo?")){
                axios
                    .post(process.env.VUE_APP_URL+`delcategories/${this.categoria.id}`, {
                        admin: this.$store.state.user
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            if(this.categoria.id){
                                this.$parent.error = {type: 1, msg: 'Eliminado correctamente'};
                                this.close();
                                this.$refs.vtable.getDatos()
                            }
                        }
                        else
                            this.$parent.error = {type: 2, msg: rs.msg};
                    })
                    .catch(error => {
                        this.$parent.error = {type: 2, msg: 'Fallo al conectar'};
                    })
            }
        },
        nuevo: function(){
            this.categoria = {films:[]};
            this.$parent.modal = true;
        }
    },
    created: function(){
        this.init();
    },
    watch: {
        $route: function(){
            this.init();
        }
    }
}
</script>